import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";

import TradeWindowSearch1 from "../images/Trade Window Search1.png";
import TradeWindowSearch2 from "../images/Trade Window Search2.png";
import TradeWindowSearch3 from "../images/Trade Window Search3.gif";
const TradeItemSearch = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <div className="lg:ml-4 px-2 p-1">
                <p className="mb-3">This feature allows you to automatically search for specific items in a user's inventory while sending a trade.</p>

                <div className="p-4 bg-gray-100 rounded-lg dark:bg-gray-700" role="alert">
                    <div className="flex items-center">
                        <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-700 dark:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Enable</span>
                        <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">Appearance</h3>
                    </div>
                    <div className="mt-2 mb-1 text-sm text-gray-700 dark:text-gray-300">
                        <p>The Trade Item Search feature appears as a search box that is placed right below the item type filter on the trade send page.</p>
                        <span className="block text-center"><img className=" w-60 inline-block m-5" src={TradeWindowSearch1} alt="red box highlighting the feature"></img></span>
                    </div>
                </div>

                <div className="mx-2 my-4">
                    <h2 className=" text-2xl">Why is it helpful?</h2>
                    <p>Some user's have very large inventories with hundreds or even thousands of items. By default, Roblox forces user's to scroll through many pages of items to find the specific item they want to add to the trade. This extension feature immensely speeds up the process of adding items to trades.</p>
                </div>

                <ImageCarousel frames={[{ image: TradeWindowSearch2, wide: true }, { image: TradeWindowSearch3, wide: true }]} />
            </div>
        </>
    );
}

export default TradeItemSearch;