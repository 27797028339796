import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";
import DisableTradeWinLossStatsRAP from "../images/Disable Trade Win Loss Stats RAP.png";
import { Link } from "react-router-dom";
const TradeWinPercent = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature modifies the <Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`./${"Trade Win/Loss Stats".replace(/ /g, '-').replace("/", "-").toLowerCase()}`} onClick={() => { props.setCurrentFeature("Trade Win/Loss Stats") }}>Trade Win/Loss Stats</Link> feature so that it only includes statistics for value, and not for RAP.</p>

            <ImageCarousel frames={[{image:DisableTradeWinLossStatsRAP}]} />

            <RelatedFeatures features={["Trade Win/Loss Stats"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default TradeWinPercent;