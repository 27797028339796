import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";
import ValuesonUserPages1 from "../images/ValuesonUserPages1.png";
const UserProfileValue = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">The Values on User Pages feature shows the total amount of value and RAP in players’ inventories on their Roblox profile page.</p>

            <p className="mb-2">This feature may take longer to initialize when the page loads for users with larger inventories.</p>
            <ImageCarousel frames={[{ image: ValuesonUserPages1, wide: true }]} />

            <RelatedFeatures features={["Values on Trading Window", "Values on Catalog Pages", "Values on Trade Lists"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default UserProfileValue;