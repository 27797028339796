import Breadcrumbs from "./Breadcrumbs";
import { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>RoValk - Contact</title>
            </Helmet>
            <Breadcrumbs pageList={["Contact us"]} />

            <div className="text-slate-700 dark:text-slate-300 text-lg lg:max-w-[50%] p-2 m-auto mt-10 !mb-5">
                <h1 className="text-4xl mb-8">Contact Us</h1>
                <p className=" mb-6">For help with, or if you have questions about the extension, please leave a message in the #support channel in our <a href="https://discord.com/invite/AhFPsHJmym" target="_blank" rel="noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Discord community server</a>.</p>

                <p>To contact the owner / developer directly, please email KDJ at <a href="mailto:kdj@rovalk.com" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">kdj@rovalk.com</a>.</p>
            </div>
        </>
    );
}

export default Contact;