import { useLayoutEffect, useEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";

import ValuesonTradeLists1 from "../images/ValuesonTradeLists1.png";
import TradeListValues2 from "../images/TradeListValues2.gif";
const TradeListValues = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    useEffect(() => {
        if (window.location.hash === '#whyAreValuesNotLoading') {
            document.getElementById("whyAreValuesNotLoading").scrollIntoView();
            document.getElementById("whyAreValuesNotLoading").focus();
            const color = localStorage.theme === 'dark' ? "#5b6100" : "#faffb5";
            document.getElementById("whyAreValuesNotLoading").style.backgroundColor = color;
        }
    }, []);
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">The Trade List Values feature adds previews of the value totals and color codes wins and losses for each individual trade on the trade list.</p>

            <div className="mx-2 my-4">
                <h2 className=" text-2xl">Why is it helpful?</h2>
                <p>Trade List Values allow you to quickly see which trades are wins, before even opening any trades. This helps you to prioritize checking trades that are promising. They also allow you to find specific trades on your trades list more easily.</p>
            </div>

            <div className="mx-2 my-4">
                <h2 className=" text-2xl">How does it work?</h2>
                <div>
                    <p>When Trade List Values is enabled, RoValk caches your inbound trades so that the values can then be displayed on the trades list. On the trade list, each of your trades will be supplied with a “value box”, which contains value totals (the top number being what you will give and the top number what you will receive). The value box is also marked green or red (green being a win and red being a loss).</p>
                </div>
            </div>
            <ImageCarousel frames={[{ image: ValuesonTradeLists1 }, { image: TradeListValues2 }]} />

            <div className="mx-2 my-4 transition-colors duration-1000" id="whyAreValuesNotLoading">
                <h2 className=" text-2xl">Why are values not loading?</h2>
                <div>
                    <p className="mb-2">Trade List Values can take some time to load in, and will load in gradually as new trades are cached.* At times, Trade List Values will only be active for a fraction of the trades on your trades list (especially when you first install the extension, or open up your browser).</p>
                    <p>If it appears that Trade List Values aren’t working, be sure to be patient and wait.</p>
                </div>
            </div>
            <div className="mx-2 my-4">
                <p className="text-sm text-gray-500">The reason loading times are not immediate is due to restrictions of both Roblox’s trades API and Google’s Alarms API.*</p>
            </div>

            <RelatedFeatures features={["Values on Catalog Pages", "Values on Trading Window", "Values on User Pages"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default TradeListValues;