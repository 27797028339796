import { Accordion } from "flowbite-react";
import { Link } from "react-router-dom";
import ripITVImage from "./images/ripITV.png";
import buyUsACoffeeImage from "./images/buyUsACoffee.png";

const HomeAccordian = () => {
    return (
        <Accordion alwaysOpen={true} className="text-gray-500 ">
            <Accordion.Panel>
                <Accordion.Title>
                    How is it free?
                </Accordion.Title>
                <Accordion.Content>
                    <p className="mb-2 text-gray-500">
                        On original release, RoValk was a paid extension, but was made free in order to benefit the most people. It is true that we have server fees to pay for, which is why it is much appreciated if you support the project by donating:
                    </p>
                    <div className="flex justify-center">
                        <a href="https://www.buymeacoffee.com/rovalk"><img className="w-1/2 m-auto" alt="Buy us a coffee" src={buyUsACoffeeImage} /></a>
                    </div>
                </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
                <Accordion.Title>
                    Didn't you used to be In-Tab Values?
                </Accordion.Title>
                <Accordion.Content>
                    <p className="mb-2 text-gray-500 ">
                        Yes. During the summer of 2022 we rebranded from In-Tab Values(which was the original title of the extension), to the catchier RoValk.
                    </p>
                    <div className="flex justify-center">
                        <img alt="RIP ITV" src={ripITVImage}></img>
                    </div>
                </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
                <Accordion.Title>
                    How does the _____ feature work?
                </Accordion.Title>
                <Accordion.Content>
                    <p className="mb-2 text-gray-500 ">
                        To learn how to use a feature, read its page on the <Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to="/features">feature documentation</Link>.
                    </p>
                </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
                <Accordion.Title>
                    Can I install the extension on mobile?
                </Accordion.Title>
                <Accordion.Content>
                    <p className="mb-2 text-gray-500 ">
                        Yes, on Android. It is recommended that you use the Kiwi browser with the extension, as that is the browser that we mobile test with.
                        <br />
                        <a target="_blank" rel="noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://kiwibrowser.com/">https://kiwibrowser.com/</a>.
                    </p>
                    <p className="text-gray-500">
                        Unfortunately Apple charges large fees to have a browser extension on their App Store. They also don't allow users to self distribute extensions for Safari. Because of this, <strong>RoValk is not supported on IOS</strong>.
                    </p>
                </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
                <Accordion.Title>
                    Can I install RoValk on Firefox?
                </Accordion.Title>
                <Accordion.Content>
                    <p className="mb-2 text-gray-500 ">
                        Firefox support is not available right now, but hopefully will be in the near future. This extension uses extension service workers, a part of Chrome's Manifest V3. Unfortunately, Firefox does not yet support this feature, but I've been told that they are working to implement it in the near future. Once they roll out this feature, I plan to promptly release a version for Firefox.
                    </p>
                </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
                <Accordion.Title>
                    Are you compatible with other extensions?
                </Accordion.Title>
                <Accordion.Content>
                    <p className="mb-2 text-gray-500 ">
                        We can not guarantee compatibility with all extensions. However, we do try to support most of the popular ones, such as RoPro, BTRoblox, RoGold, and Roblox+.
                    </p>
                </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
                <Accordion.Title>
                    Who made this extension?
                </Accordion.Title>
                <Accordion.Content>
                    <p className="mb-2 text-gray-500 ">
                        RoValk was created and is maintained by software developer KDJ (alias <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://devforum.roblox.com/u/excessenergy">ExcessEnergy</a> on Roblox).
                        <br />
                        Item values are provided by <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://devforum.roblox.com/u/rolimon">Rolimon</a>.
                        <br />
                        <br />
                        Read more on the <Link to="/about" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">About us</Link> page.
                    </p>
                </Accordion.Content>
            </Accordion.Panel>
        </Accordion>

    );
}

export default HomeAccordian;