import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";

import UserProfileLinks1 from "../images/UserProfileLinks1.png";
import rolimonsLinkImg from "../images/rolimonsLinkDark.svg";
const UserProfileLinks = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">The User Profile Links feature adds links to the profiles of players on rolimons.com.</p>

            <div className="p-4 bg-gray-100 rounded-lg dark:bg-gray-700" role="alert">
                <div className="flex items-center">
                    <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-700 dark:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Enable</span>
                    <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">Appearance</h3>
                </div>
                <div className="mt-2 mb-1 text-sm text-gray-700 dark:text-gray-300">
                    <p>This feature, along with most other features in the Links category, appear as <img className="w-6 inline" src={rolimonsLinkImg} alt="asset link icon" /> icons placed around your trading related pages. When clicked, take you to the related page on rolimons.com.</p>
                </div>
            </div>

            <div className="mx-2 my-4">
                <h2 className=" text-2xl">Locations</h2>
                <p>When enabled, this feature will appear on:</p>
                <ul className=" list-disc">
                    <li className="ml-3"><p>Trade details pages</p></li>
                    <li className="ml-3"><p>User profiles</p></li>
                </ul>
            </div>

            <ImageCarousel frames={[{ image: UserProfileLinks1, wide: true }]} />
        </div>
    );
}

export default UserProfileLinks;