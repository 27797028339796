const ChangeList = (props) => {
    return (
        <div className="text-left dark:bg-bgLightDark">
            {(props.update.additions || props.update.fixes) && (
                <h3 className="text-3xl font-medium p-2 pl-5 font-[AvertaExtraBold]">Changelog</h3>
            )}
            {props.update.additions && (
                <>
                    <div className="flex pl-5"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-3" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg><p className="mt-[-3px]">Changes</p></div>
                    <ul className="px-5 mb-5">
                        {props.update.additions.map((change, index) => (
                            <li key={index} className="p-2 border-b-[1px] last:border-b-0 border-slate-200 hover:bg-lightGrey hover:dark:bg-slate-800">
                                <p dangerouslySetInnerHTML={{__html: change}}></p>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {props.update.fixes && (
                <>
                    <div className="flex pl-5"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg><p className="mt-[-3px]">Bug Fixes</p></div>
                    <ul className="px-5 mb-5">
                        {props.update.fixes.map((fix, index) => (
                            <li key={index} className="p-2 border-b-[1px] last:border-b-0 border-slate-200 hover:bg-lightGrey hover:dark:bg-slate-800">
                               <p dangerouslySetInnerHTML={{__html: fix}}></p>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
}

export default ChangeList;