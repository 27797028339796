import { Link } from "react-router-dom";
import rovalkThemeSVG from "./RoValkTheme.svg";
const Footer = () => {
    return (
        <>
            <div className="spacer h-36"></div>
            <footer className="!p-4 rounded-t-lg shadow md:flex md:items-center md:justify-between md:p-6 bg-white dark:!bg-gray-800 absolute bottom-0 w-full border-t-[1px] border-slate-200 dark:border-black z-10">
                <div>
                    <div className="flex items-center flex-shrink-0 text-theme mr-6">
                    <img src={rovalkThemeSVG} alt="RoValk logo" className="w-8 h-8 mr-1 mb-1"></img>
                        <span className="font-semibold text-xl tracking-tight">RoValk</span>
                    </div>
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-300">© {new Date().getFullYear()} <a href="/" className="hover:underline">RoValk™</a>. All Rights Reserved.
                    </span>
                </div>
                <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-300 sm:mt-0">
                    <li>
                        <Link className="mr-4 hover:underline md:mr-6" to="/about">About</Link>
                    </li>
                    <li>
                        <Link className="mr-4 hover:underline md:mr-6" to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link className="mr-4 hover:underline md:mr-6" to="/contact">Contact</Link>
                    </li>
                </ul>
            </footer>
        </>
    );
}

export default Footer;