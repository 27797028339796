import { Link } from "react-router-dom";
const RelatedFeatures = (props) => {
    return (
        <div className="m-2">
            <h2 className="text-xl">Related Features:</h2>
            <ul>
                {props.features.map((featureName, index) => (
                    <Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`./${featureName.replace(/ /g, '-').replace("/", "-").toLowerCase()}`} onClick={() => { props.setCurrentFeature(featureName) }} key={index}> <li>{featureName}</li></Link>
                ))}
            </ul>
        </div>
    );
}

export default RelatedFeatures;