import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";

import rolimonsLinkImg from "../images/rolimonsLinkDark.svg";
import AddItemOwnershipHistoryUAIDLinks1 from "../images/Add Item Ownership History (UAID) Links1.png";
import AddItemOwnershipHistoryUAIDLinks2 from "../images/Add Item Ownership History (UAID) Links2.png";
import AddItemOwnershipHistoryUAIDLinks3 from "../images/Add Item Ownership History (UAID) Links3.png";
import AddItemOwnershipHistoryUAIDLinks4 from "../images/Add Item Ownership History (UAID) Links4.gif";

const AddItemOwnershipHistoryUAIDLinks = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature adds links to rolimons.com UAID specific item pages. This means that instead of a link to an item's general page, this feature provides links to a page which shows detailed information about the history of the specific copy.</p>

            <div id="alert-additional-content-5" className="p-4 bg-gray-100 rounded-lg dark:bg-gray-700" role="alert">
                <div className="flex items-center">
                    <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-700 dark:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">Appearance</h3>
                </div>
                <div className="mt-2 mb-1 text-sm text-gray-700 dark:text-gray-300">
                    <p>This feature, unlike all of the other features in the Quick Links category, does not appear as an <img className="w-6 inline" src={rolimonsLinkImg} alt="asset link icon"/> icon, but modifies the serial tag, so that when clicked you are directed to the page specific to that item’s UAID on rolimons.com.<span className="block text-center"><img className="w-20 inline-block m-5" src={AddItemOwnershipHistoryUAIDLinks1} alt="red box highlighting the feature"></img><img className="w-20 inline-block m-5" src={AddItemOwnershipHistoryUAIDLinks2} alt="red box highlighting the feature"></img><img className=" w-40 inline-block m-5" src={AddItemOwnershipHistoryUAIDLinks3} alt="red box highlighting the feature"></img></span></p>
                </div>
            </div>

            <div className="mx-2 my-4">
                <h2 className=" text-2xl">Locations</h2>
                <p>When enabled, this feature will appear on Trade details, Send, and Counter pages.</p>
            </div>

            <ImageCarousel frames={[{image:AddItemOwnershipHistoryUAIDLinks4, wide:true}]} />

            <RelatedFeatures features={["Add Item Profile Links", "Add User Profile Links"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default AddItemOwnershipHistoryUAIDLinks;