import { Helmet } from "react-helmet";

const DiscordRedirect = () => {
    const discordJoinURL = "https://discord.com/invite/ENA55eTzDa";
    setTimeout(() => {
        window.location.replace(discordJoinURL);
    }, 3000);
    return (
        <div className="text-center dark:text-slate-300">
            <Helmet>
                <title>Join the RoValk Discord Server</title>
                <meta name="description" content="Redirects to RoValk Discord join URL" />
            </Helmet>
            <div className="m-10 mb-5 text-3xl">You are being redirected to Discord.</div>
            <p className="text-lg">If you are not redirected within 5 seconds, <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={discordJoinURL}>click here</a>.</p>
        </div>
    );
}

export default DiscordRedirect;