import options from "./data/options.json";
import { useLayoutEffect } from "react";
import { Link } from 'react-router-dom';
import FeatureOverview from './featureComponents/FeatureOverview';
import ValuesonTradingWindow from './featureComponents/Values on Trading Window';
import ValuesonTradeLists from './featureComponents/Values on Trade Lists';
import PostTaxTradeValue from './featureComponents/Post-Tax Trade Value';
import ValuesonUserPages from './featureComponents/Values on User Pages';
import ValuesonCatalogPages from './featureComponents/Values on Catalog Pages';
import FlagRareItems from './featureComponents/Flag Rare Items';
import FlagProjectedItems from './featureComponents/Flag Projected Items';
import AddItemProfileLinks from './featureComponents/Add Item Profile Links';
import AddItemOwnershipHistoryUAIDLinks from './featureComponents/Add Item Ownership History (UAID) Links';
import AddUserProfileLinks from './featureComponents/Add User Profile Links';
import AddSendButton from './featureComponents/Add Send Button';
import TradeWinLossStats from './featureComponents/Trade Win Loss Stats';
import InboundTradeNotifications from './featureComponents/Inbound Trade Notifications';
import DeclinedTradeNotifications from './featureComponents/Declined Trade Notifications';
import CompletedTradeNotifications from './featureComponents/Completed Trade Notifications';
import TradeWindowSearch from './featureComponents/Trade Window Search';
import ShowUserRoliBadges from './featureComponents/Show User RoliBadges';
import MobileTradeItemsButton from "./featureComponents/Mobile Trade Items Button";
import DisableWinLossStatsRAP from "./featureComponents/Disable Win Loss Stats RAP";

import './index.css';

import { Helmet } from "react-helmet";
const FeaturesList = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const components = {
        FeatureOverview,
        ValuesonTradingWindow,
        ValuesonTradeLists,
        PostTaxTradeValue,
        ValuesonCatalogPages,
        ValuesonUserPages,
        FlagRareItems,
        FlagProjectedItems,
        AddItemProfileLinks,
        AddItemOwnershipHistoryUAIDLinks,
        AddUserProfileLinks,
        AddSendButton,
        TradeWinLossStats,
        InboundTradeNotifications,
        TradeWindowSearch,
        DeclinedTradeNotifications,
        CompletedTradeNotifications,
        ShowUserRoliBadges,
        MobileTradeItemsButton,
        DisableWinLossStatsRAP
    };
    function Feature() {
        const SpecificFeature = components[props.currentFeature.replace(/ /g, '').replace('(', '').replace(')', '').replace('/', '').replace('-', '')];
        return <SpecificFeature setCurrentFeature={props.setCurrentFeature} />;
    }

    function getPreviousOption() {
        var currentFeatureObjectIndex = options.findIndex(obj => {
            return obj.name === props.currentFeature;
        });
        function getPreviousObject(index) {
            if (typeof options[index - 1] !== "string") {
                return options[index - 1];
            } else {
                return getPreviousObject(index - 1);
            }
        }
        return getPreviousObject(currentFeatureObjectIndex);
    }
    function getNextOption() {
        var currentFeatureObjectIndex = props.currentFeature === "Feature Overview" ? 0 : options.findIndex(obj => {
            return obj.name === props.currentFeature;
        });
        function getNextObject(index) {
            if (typeof options[index + 1] !== "string") {
                return options[index + 1];
            } else {
                return getNextObject(index + 1);
            }
        }
        return getNextObject(currentFeatureObjectIndex);
    }
    return (
        <div className="text-slate-700 dark:text-slate-300">
            <Helmet>
                <title>RoValk - {props.currentFeature}</title>
                <meta name="description" content={props.currentFeature === "Feature Overview" ? "Feature documentation for RoValk, a browser extension designed to improve the limited item trading experience on roblox.com." : `Feature documentation for RoValk's ${props.currentFeature} feature.`} />
            </Helmet>
            <div className="text-2xl font-medium lg:text-4xl text-center lg:!text-left mt-2">
                <h1 className="lg:ml-4">{props.currentFeature}</h1>
            </div>
            <Feature />
            <div className="flex justify-between border-y-[1px] py-5 border-slate-200 mt-2 p-2">
                <Link className={props.currentFeature === "Feature Overview" ? "pointer-events-none" : ""} to={getPreviousOption()?.path || "#"} onClick={() => { props.setCurrentFeature(getPreviousOption()?.name || "Feature Overview") }}>
                    <button className={`btn btn-sm md:btn-md btn-ghost gap-2 normal-case lg:gap-3 ${props.currentFeature === "Feature Overview" && "btn-disabled"}`}>
                        <svg className="h-6 w-6 fill-current md:h-8 md:w-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>
                        <div className="flex flex-col items-start"><span className="text-base-content/50 hidden text-xs font-normal md:block dark:text-slate-700">Prev</span> <span className="dark:text-slate-300">{props.currentFeature !== "Feature Overview" && (getPreviousOption()?.name || "Feature Overview")}</span></div>
                    </button>
                </Link>
                <Link className={getNextOption()?.name === undefined ? "pointer-events-none" : ""} to={getNextOption()?.path || "#"} onClick={() => { props.setCurrentFeature(getNextOption().name) }}>
                    <button className={`btn btn-sm md:btn-md gap-2 normal-case lg:gap-3 ${getNextOption()?.name === undefined && "btn-disabled"}`}><div className="flex flex-col items-end"><span className="text-white hidden text-xs font-normal md:block">Next</span> <span className="text-white">{getNextOption()?.name}</span></div> <svg className="h-6 w-6 fill-white md:h-8 md:w-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path></svg></button>
                </Link>
            </div>
            <p className="text-gray-500 text-sm m-5">To report an issue with, or suggest an addition to this page, please contact the developer at kdj@rovalk.com</p>
        </div>
    );
}

export default FeaturesList;