import { Carousel } from "flowbite-react";

const ImageCarousel = (props) => {
  return (
    <div className="h-96 bg-[#4B5563]">
      <Carousel slideInterval={10000}>
        {props.frames.map((frame, index) => {
          if (frame.wide === true) {
            return (
              <div key={index} className="h-auto sm:h-full p-1">
                <img
                  src={frame.image}
                  alt="feature"
                  className="h-full m-auto object-contain"
                  loading="lazy"
                />
              </div>
            )
          } else {
            return (
              <div key={index} className="h-full p-1">
                <img
                  src={frame.image}
                  alt="feature"
                  className="h-full m-auto object-contain"
                  loading="lazy"
                />
              </div>
            )
          }
        })}
      </Carousel>
    </div>

  );
}

export default ImageCarousel;