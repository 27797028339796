import updateLog from "./updateLog.json";
import ChangeList from "./ChangeList";
import DiscordInvite from "./DiscordInvite";
import Breadcrumbs from "./Breadcrumbs";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const UpdateLog = () => {
    let [searchParams] = useSearchParams();
  
    return (
        <div className="App">
            <Helmet>
                <title>RoValk - Update log</title>
            </Helmet>

            {searchParams.get("upgraded") === "true" ? (
                <header className="App-header text-center">
                    <hr className='border-lightTheme' />
                    <div className="bg-theme p-5">
                        <h1 className="text-2xl font-medium lg:text-4xl text-white tracking-wide mb-3">RoValk has been upgraded</h1>
                        <p className="text-white text-base tracking-wide">to version {updateLog[0].version}</p>
                    </div>
                </header>
            ) : (
                <Breadcrumbs pageList={["Privacy policy"]}/>
            )}
            
            <div className="text-slate-700 dark:text-slate-300 text-lg m-auto mt-10 text-center">
                <h2 className="text-2xl font-medium lg:text-4xl mb-3">Update Log</h2>
                {updateLog.map((update, index) => (
                    <div key={index}>
                        {index === 0 ? (
                            <div key={index} className="max-w-[95%] lg:max-w-[75%] m-auto shadow-lg pb-1">
                                <div className="bg-theme text-white tracking-wide text-2xl">Current Release</div>
                                <div className="px-3">
                                    <div className="border-b-[1px] border-slate-200 flex justify-between">
                                        <span className="">v{update.version}</span>
                                        <span className="">{update.releaseDate}</span>
                                    </div>
                                </div>
                                <ChangeList update={update} />
                                {update.notes && (
                                    <div className="text-left m-5">
                                        <h2 className="text-xl font-[AvertaExtraBold]">Developer notes</h2>
                                        <p className="">{update.notes}</p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div key={index} className="max-w-[95%] lg:max-w-[65%] mt-5 mx-auto shadow-lg pb-1">
                                <div className="collapse">
                                    <input type="checkbox" className="peer" />
                                    <div className="collapse-title pr-3">
                                        <div className="px-1">
                                            <div className="border-b-[1px] border-slate-200 flex justify-between">
                                                <span className="">v{update.version}</span>
                                                <span className="">{update.releaseDate}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="collapse-content p-1">
                                        <ChangeList update={update} />
                                        {update.notes && (
                                            <div className="text-left m-5">
                                                <h2 className="text-xl font-[AvertaExtraBold]">Developer notes</h2>
                                                <p className="">{update.notes}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="text-center mt-1 text-slate-700 dark:text-slate-300">Logs further back in time are available in the #updates channel of our Discord server.</div>
            <div className="m-5 text-center border-t-[1px] border-slate-200 text-slate-700 dark:text-slate-300">
                <p className="mb-2">Please join our community Discord server to get notified when updates are released:</p>
                <DiscordInvite/>
            </div>
        </div>
    );
}

export default UpdateLog;