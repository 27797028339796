import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";

import SendButton1 from "../images/SendButton1.png";
const UserProfileRAP = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">The Send Button feature adds a fourth button alongside the Accept, Counter, and Decline buttons on Inbounds and Outbounds on the trades page. When clicked this new button titled “Send” initiates the sending of a brand new trade, compared with the Counter button which allows you to modify a trade and send it back.</p>

            <ImageCarousel frames={[{image:SendButton1, wide:true}]} />
        </div>
    );
}

export default UserProfileRAP;