import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";

import CatalogValues1 from "../images/CatalogValues1.jpg";
import CatalogValues2 from "../images/CatalogValues2.jpg";
const CatalogValues = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">The Values on Catalog Pages feature integrates values into items on the Roblox catalog.</p>
            <p className="mb-2">This feature is quite similar to <Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`./${"Values on Trading Window".replace(/ /g, '-').toLowerCase()}`} onClick={() => { props.setCurrentFeature("Values on Trading Window") }}>Values on Trading Window</Link>, but provides values for catalog related pages(Avatar shop, item profile pages, etc.) instead of trade pages.</p>
            <ImageCarousel frames={[{image:CatalogValues1, wide:true}, {image:CatalogValues2, wide:true}]} />

            <RelatedFeatures features={["Values on Trading Window", "Values on Trade Lists", "Values on User Pages"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default CatalogValues;