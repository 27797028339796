import { useLayoutEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
import kdjLogo from "./images/kdjLogo.jpg";
import { Helmet } from "react-helmet";

const About = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>RoValk - About us</title>
            </Helmet>
            <Breadcrumbs pageList={["About us"]} />
            <div className="App text-slate-700 dark:text-slate-300">
                <div className=" text-lg m-auto mt-10 text-center">
                    <h2 className="text-2xl font-medium lg:text-4xl mb-3">About us</h2>
                </div>
                <div className="mt-10 p-6 shadow-lg sm:max-w-[66.6666%] mx-2 sm:mx-auto rounded-md text-left mb-12 dark:bg-bgLightDark">
                    <h3 className="text-2xl mb-3">Some history</h3>
                    <p>
                        Hello, I'm KDJ, developer of RoValk. Back in early 2020, I was an avid trader of limited items on the Roblox platform. There was just one thing that I thought would immensely improve the Roblox trading experience: better and more extensive tooling, coupled with the incorporation of third party values into the site. Thankfully I happened to be a web developer and so acted on this idea. In late 2020 RoValk, originally called In-Tab values, was released. The extension was paid access until the summer of 2022, in which I decided to make it completely free in order to benefit the most people. Today, I continue development of the extension and enjoy seeing the number of people grow who find this extension useful. I hope you are one of them!
                    </p>
                    <div className="pt-5 pl-5">
                        <span className=" text-2xl">- KDJ</span>
                        <img src={kdjLogo} alt="KDJ logo" className="w-28 rounded-lg" />
                    </div>
                </div>

                <div className="mt-10 p-6 shadow-lg sm:max-w-[66.6666%] mx-2 sm:mx-auto rounded-md text-left mb-12 dark:bg-bgLightDark">
                    <h3 className="text-2xl  mb-3">Special thanks to</h3>
                    <div className="mt-5">
                        <strong><h4 className="text-lg">my moderators and extension testers</h4></strong>
                        <ul className="list-disc pl-3">
                            <li>Spork,</li>
                            <li>Petabyte_Fox,</li>
                            <li>and Ayan</li>
                        </ul>
                        <p>who have and continue to give invaluable input and feedback through the development of this extension. Thank you also for the amount of time you've put into interacting with our community.</p>
                    </div>
                    <div className="mt-5">
                        <strong><h4 className="text-lg">The entire community of traders who support this extension</h4></strong>
                        <p>
                            even as it has evolved over the years. Your praise and criticism of the extension both are very motivating and helpful to me as a developer.
                        </p>
                    </div>


                    <div className=" mt-7">
                        <h3 className="text-xl  mb-3">Slightly less thanks to</h3>
                        <p>Anyone who read this entire about page. Congratulations, you have a greater than average attention span.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;