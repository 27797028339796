import { useLayoutEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
const NotFound = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Breadcrumbs pageList={["404"]}/>

            <div className="text-slate-700 text-lg max-w-[50%] m-auto mt-10">
                <h1 className="text-4xl mb-8">404</h1>

                <p>This page no longer exists</p>
                <p className="text-sm"><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 mr-3" href="/">Return to home</a></p>
            </div>
        </>
    );
}

export default NotFound;