import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";

import FlagRare1 from "../images/FlagRare1.jpg";
import rareImg from "../images/rare.png";
const FlagRare = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature marks items that are rare according to <a href="https://rolimons.com" className="text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer">rolimons.com</a> with the <img className="w-6 inline" src={rareImg} alt="rare icon"/> icon.</p>

            <ImageCarousel frames={[{image:FlagRare1}]} />

            <RelatedFeatures features={["Flag Projected Items"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default FlagRare;