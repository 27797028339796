import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";

import TradeValues1 from "../images/TradeValues1.jpg";
import TradeValues2 from "../images/TradeValues2.jpg";
import TradeValues3 from "../images/TradeValues3.jpg";
import alertImage from "../images/Values on Trade Window Alert.png";
const ValuesonTradingWindow = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature adds third party item values sourced from rolimons.com to your trading related pages. Values on the page are automatically updated every minute.</p>

            <div className="mx-2 my-4">
                <h2 className=" text-2xl">Why is it helpful?</h2>
                <p>Normally checked values while trading requires toggling between websites. Trade Values <strong>saves you hours of time</strong> by integrating values directly into roblox.com.</p>
            </div>

            <ImageCarousel frames={[{image:TradeValues1},{image:TradeValues2},{image:TradeValues3, wide:true}]} />

            <div className="mx-2 my-4">
                <h2 className=" text-2xl">What are values?</h2>
                <div>
                    <p>
                        By default Roblox only shows you each item’s RAP(Recent Average Price), which is an exponential moving average price of the item based on recent sales.
                        However, the RAP does not take into consideration what the item is being traded for.
                        To better represent what items actually trade for, third party sites such as <a href="https://rolimons.com" className="text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer">rolimons.com</a>, provide values for traders to use alongside RAP.
                        The RAP and Value of items will often times significantly differ, for several reasons such as:</p>
                    <ul className="m-2 list-disc">
                        <li>Items may have greater than average demand, which increases value.</li>
                        <li>Expensive items that are very rarely bought but frequently traded don’t change their RAP.</li>
                        <li>Items can be projected, which throws off their RAP.</li>
                    </ul>

                    <p>Values are an important part of the trading ecosystem.</p>
                </div>
            </div>

            <div className="p-4 bg-yellow-100 rounded-lg dark:bg-yellow-700 mb-2" role="alert">
                <div className="flex items-center">
                    <svg aria-hidden="true" className="mr-2 w-5 h-5 text-yellow-700 dark:text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <h3 className="text-lg font-medium text-yellow-700 dark:text-yellow-300">Visual bug on mobile</h3>
                </div>
                <div className="mt-2 mb-1 text-sm text-yellow-700 dark:text-yellow-300">
                    <p>It is known that on mobile there will be large visual gaps in between items on the trades page. This is not due to RoValk, and will occur whether or not RoValk is installed because of poorly optimized UI for mobile on the part of Roblox.</p>
                    <span className="block text-center"><img className=" w-60 inline-block m-5" src={alertImage} alt="depiction of the visual bug"></img></span>
                </div>
            </div>

            <RelatedFeatures features={["Values on Catalog Pages", "Values on Trade Lists", "Values on User Pages", "Post-Tax Trade Value"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default ValuesonTradingWindow;