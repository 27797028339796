import { Link } from "react-router-dom";
import rovalkSVG from "./RoValk.svg";
import discordImage from "./images/Discord.svg";
import RobloxGroupImage from "./images/RobloxGroup.svg";
import BuyMeACoffeeImage from "./images/BuyMeACoffee.svg";
const Navbar = () => {
    return (
        <>
            <div className="navbar bg-theme text-white">
                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex="0" className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </label>
                        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow rounded-box w-52 bg-theme">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/features">Features</Link></li>
                            <li><Link to="/updates">Update Log</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                        </ul>
                    </div>
                    <Link className="btn btn-ghost normal-case text-xl flex flex-nowrap" to="/">
                        <img src={rovalkSVG} alt="RoValk logo" className="w-8 h-8 mr-1 mb-1"></img>
                        <span className="font-semibold text-xl tracking-tight">RoValk</span>
                    </Link>
                </div>
                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal p-0">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/features">Features</Link></li>
                        <li><Link to="/updates">Update Log</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                    </ul>
                </div>
                <div className="navbar-end">
                    <div className="flex gap-2 items-center">
                        <a className="btn bg-theme border-white hover:border-transparent hover:text-theme hover:bg-white text-white" target="_blank" rel="noreferrer" href="https://chrome.google.com/webstore/detail/oifhhghkjjhnhonfghmdmkjbomnhblbf">Install</a>
                        <div className="hidden sm:flex gap-2 items-center">
                            <a href="https://discord.com/invite/ENA55eTzDa" target="_blank" rel="noreferrer" className="w-10 h-10 hover:scale-110 transition-transform" style={{ backgroundImage: `url(${discordImage})` }}></a>
                            <a href="https://www.roblox.com/groups/15511376" target="_blank" rel="noreferrer" className="w-10 h-10 hover:scale-110 transition-transform" style={{ backgroundImage: `url(${RobloxGroupImage})` }}></a>
                            <a href="https://www.buymeacoffee.com/rovalk" target="_blank" rel="noreferrer" className="w-10 h-10 hover:scale-110 transition-transform" style={{ backgroundImage: `url(${BuyMeACoffeeImage})` }}></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="justify-center gap-2 flex sm:!hidden items-center bg-theme p-1">
                <a href="https://discord.com/invite/ENA55eTzDa" target="_blank" rel="noreferrer" className="w-10 h-10 hover:scale-110 transition-transform" style={{ backgroundImage: `url(${discordImage})` }}></a>
                <a href="https://www.roblox.com/groups/15511376" target="_blank" rel="noreferrer" className="w-10 h-10 hover:scale-110 transition-transform" style={{ backgroundImage: `url(${RobloxGroupImage})` }}></a>
                <a href="https://www.buymeacoffee.com/rovalk" target="_blank" rel="noreferrer" className="w-10 h-10 hover:scale-110 transition-transform" style={{ backgroundImage: `url(${BuyMeACoffeeImage})` }}></a>
            </div>
        </>
    );
}

export default Navbar;