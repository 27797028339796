import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";

import PostTradeTaxValue1 from "../images/Post-Trade Tax Value1.png"
const SubtractRobuxFeeFromValue = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">When you receive Robux in a trade, Roblox takes 30% of it as a tax. This feature displays a reminder on trades where you receive Robux that you will not actually receive the amount of value posted on the trade due to the Robux tax.</p>

            <ImageCarousel frames={[{image:PostTradeTaxValue1, wide:true}]} />

            <RelatedFeatures features={["Values on Trading Window"]} setCurrentFeature={props.setCurrentFeature} />

        </div>
    );
}
 
export default SubtractRobuxFeeFromValue;