import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import RelatedFeatures from "../RelatedFeatures";
import TradeWinLossStats1 from "../images/Trade Win Loss Stats1.png";
const TradeWinPercent = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature shows you how much you are winning in value and RAP on the trade details page, and while you are countering / sending trades.</p>

            <ImageCarousel frames={[{image:TradeWinLossStats1}]} />

            <RelatedFeatures features={["Disable Win/Loss Stats RAP", "Trade Values", "Absolute Trade Win"]} setCurrentFeature={props.setCurrentFeature} />
        </div>
    );
}

export default TradeWinPercent;