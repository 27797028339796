import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";

import ShowUserRoliBadges1 from "../images/ShowUserRoliBadges1.png";
const UserProfileRAP = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature displays users' <a href="https://rolimons.com" className="text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer">rolimons.com</a> RoliBadges on their Roblox profile alongside their Roblox badges.</p>
            <div className="p-4 bg-yellow-100 rounded-lg dark:bg-yellow-700 mb-4" role="alert">
                <div className="flex items-center">
                    <svg aria-hidden="true" className="mr-2 w-5 h-5 text-yellow-700 dark:text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <h3 className="text-lg font-medium text-yellow-700 dark:text-yellow-300">Feature Incompatibility</h3>
                </div>
                <div className="mt-2 mb-1 text-sm text-yellow-700 dark:text-yellow-300">
                    <p>If this feature looks strange, it is probably because you have the BTRoblox extension installed. Unfortunately there is an incompatibility with the BTRoblox extension right now that distorts the UI for this feature. Uninstalling BTRoblox should fix the problem, or you can disable the "Show User RoliBadges" feature in the RoValk extension options.</p>
                </div>
            </div>
            <ImageCarousel frames={[{image:ShowUserRoliBadges1, wide:true}]} />
        </div>
    );
}

export default UserProfileRAP;