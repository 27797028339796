const FeatureOverview = () => {
    return (
        <div className="lg:ml-4 px-2 p-1 ">
            <p className="mb-3">RoValk's features are designed to make trading limited items on the Roblox platform more efficient, profitable, and fun.</p>

            <div className="overflow-x-auto mb-10">
                <p className="mb-2">For organizational purposes, RoValk's features are grouped into several categories:</p>

                <div className="overflow-x-auto relative ">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-300 ">
                        <thead className="text-xs text-gray-700 dark:text-gray-400 uppercase bg-gray-50 dark:bg-bgLightDark ">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Category
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Purpose
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    # Features
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                                    Values
                                </th>
                                <td className="py-4 px-6">
                                    Inserts item values from <a href="https://rolimons.com" className="text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer">rolimons.com</a> into your trading pages
                                </td>
                                <td className="py-4 px-6">
                                    4
                                </td>
                            </tr>
                            <tr className="bg-gray-50 dark:bg-bgLightDark">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                                    Trading
                                </th>
                                <td className="py-4 px-6">
                                    Helpful features that make trading easier and faster
                                </td>
                                <td className="py-4 px-6">
                                    3
                                </td>
                            </tr>
                            <tr className="">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap ">
                                    Trade Notifications
                                </th>
                                <td className="py-4 px-6">
                                    Receive desktop push notifications whenever one of your trades is accepted, declined, or when you receive an inbound trade
                                </td>
                                <td className="py-4 px-6">
                                    3
                                </td>
                            </tr>
                            <tr className="bg-gray-50 dark:bg-bgLightDark ">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap ">
                                    Item Flags
                                </th>
                                <td className="py-4 px-6">
                                    Marks items that are considered rare, and also warns you by marking items that are projected
                                </td>
                                <td className="py-4 px-6">
                                    2
                                </td>
                            </tr>
                            <tr className="bg-gray-50 dark:bg-bgLightDark ">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap ">
                                    Links
                                </th>
                                <td className="py-4 px-6">
                                    Quick links that take you to the rolimons.com pages associated with users and items
                                </td>
                                <td className="py-4 px-6">
                                    3
                                </td>
                            </tr>
                            <tr className="bg-gray-50 dark:bg-bgLightDark ">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap ">
                                    Other
                                </th>
                                <td className="py-4 px-6">
                                    Miscellaneous other helpful features
                                </td>
                                <td className="py-4 px-6">
                                    2
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <p className="text-center">To get started learning about RoValk's numerous features, press <strong>Next</strong>.</p>
        </div>
    );
}

export default FeatureOverview;