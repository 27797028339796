import { useState, useEffect } from 'react';

const DiscordInvite = (props) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (document.getElementById("discordInvite") === null) {
      if (window.discordInvite) {
        if (!props.small) {
          window.discordInvite.init({
            inviteCode: 'ENA55eTzDa',
            title: 'RoValk',
          });
        } else {
          window.discordInvite.init({
            inviteCode: 'ENA55eTzDa',
            title: 'RoValk',
            hideIntro: true,
            miniMode: true
          });
        }
        window.discordInvite.render();
      }
    }
  }, [isDesktop, props.small]);

  return (
    <div className="text-center">
      {(isDesktop || props.small) ? (
        <div id="discordInviteBox"></div>
      ) : (
        <button type="button" className="discordLink noselect !inline-block" id="callToAction"><div id="buttonText" className="noselect">Join</div></button>
      )}
    </div>
  );
}

export default DiscordInvite;