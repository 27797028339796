import { useLayoutEffect, useEffect } from "react";
import rovalkSVG from "./RoValk.svg";
import chromeWebStoreImage from "./images/chromeWebStore.png";
/* import firefoxWebStoreImage from "./images/firefoxWebStore.png"; */
import DiscordInvite from "./DiscordInvite";
import FeatureCard from "./FeatureCard";
import HomeAccordian from "./HomeAccordian";

import TradeValues1 from "./images/TradeValues1.jpg";
import TradeListValues3 from "./images/TradeListValues3.jpg";
import TradeNotifier1 from "./images/TradeNotifier1.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    useEffect(() => {
        if (window.location.hash === '#faq') {
            document.getElementById("faq").scrollIntoView();
            document.getElementById("faq").focus();
        }
    }, []);
    function getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf("edge") > -1: return "MS Edge";
            case agent.indexOf("edg/") > -1: return "Edge";
            case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
            case agent.indexOf("trident") > -1: return "MS IE";
            case agent.indexOf("firefox") > -1: return "Firefox";
            default: return null;
        }
    }

    return (
        <>
            <Helmet>
                <title>RoValk - The Roblox Trading Extension</title>
            </Helmet>
            <div className="App">
                <div className="text-white text-lg m-auto mt-10 text-center">
                    <div className="inline-block p-2">
                        <div className="bg-theme inline-block rounded-md p-2 px-10 mb-2">
                            <div className="inline-flex flex-nowrap items-center">
                                <img src={rovalkSVG} alt="RoValk logo" className="lg:w-36 lg:h-36 w-16 h-16 mr-1 mb-1"></img>
                                <h2 className="lg:text-9xl text-5xl font-medium mb-3">RoValk</h2>

                            </div>
                            <div className="text-xl">The original Roblox trading extension</div>
                        </div>
                        <br></br>
                        <div className="flex flex-wrap justify-between gap-2 mb-5">
                            <div className="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 inline-block float-left">
                                <a href="https://www.rolimons.com/" className="sm:w-auto sm:h-[72px] bg-[#323439] hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-md inline-flex items-center justify-center px-2 py-2 sm:px-4 sm:py-3 ">
                                    <img className="object-cover lg:p-1 h-6 lg:h-12 mr-1 rounded-t-lg md:rounded-none md:rounded-l-lg" src="https://www.rolimons.com/images/logo-icon-blue.svg" alt=""></img>
                                    <div className="text-left">
                                        <div className="mb-1 text-xs">Powered by</div>
                                        <div className="-mt-1 font-sans text-sm font-semibold">Rolimons</div>
                                    </div>
                                </a>
                            </div>
                            {/* <a className="sm:!hidden" href={getBrowserName() === "Firefox" ? "https://addons.mozilla.org/en-US/firefox/addon/in-tab-values/" : "https://chrome.google.com/webstore/detail/in-tab-values-the-roblox/oifhhghkjjhnhonfghmdmkjbomnhblbf"} target="_blank" rel="noreferrer">
                                <button type="button" className="text-white bg-theme hover:bg-[#420BA1] transition-colors focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-base px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500">
                                    <img className="object-cover p-1 h-8 w-8 mr-3 text-xl rounded-t-lg md:rounded-none md:rounded-l-lg" src={getBrowserName() === "Firefox" ? firefoxWebStoreImage : chromeWebStoreImage} alt="install the extension"></img>
                                    <p>Install on {getBrowserName() || "the Chrome Web Store"}</p>
                                </button>
                            </a> enable when Firefox version released */}
                            <a className="sm:!hidden" href="https://chrome.google.com/webstore/detail/in-tab-values-the-roblox/oifhhghkjjhnhonfghmdmkjbomnhblbf" target="_blank" rel="noreferrer">
                                <button type="button" className="text-white bg-theme hover:bg-[#420BA1] transition-colors focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-base px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500">
                                    <img className="object-cover p-1 h-8 w-8 mr-3 text-xl rounded-t-lg md:rounded-none md:rounded-l-lg" src={chromeWebStoreImage} alt="install the extension"></img>
                                    <p>Install on {(getBrowserName() !== "Firefox" && getBrowserName())  || "the Chrome Web Store"}</p>
                                </button>
                            </a>
                            <div className="hidden sm:!block">
                                <DiscordInvite small={true} />
                            </div>
                        </div>
                        {/* <a className="hidden sm:!block" href={getBrowserName() === "Firefox" ? "https://addons.mozilla.org/en-US/firefox/addon/in-tab-values/" : "https://chrome.google.com/webstore/detail/in-tab-values-the-roblox/oifhhghkjjhnhonfghmdmkjbomnhblbf"} target="_blank" rel="noreferrer">
                                <button type="button" className="text-white bg-theme hover:bg-[#420BA1] transition-colors focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-base px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500">
                                    <img className="object-cover p-1 h-8 w-8 mr-3 text-xl rounded-t-lg md:rounded-none md:rounded-l-lg" src={getBrowserName() === "Firefox" ? firefoxWebStoreImage : chromeWebStoreImage} alt="install the extension"></img>
                                    <p>Install on {getBrowserName() || "the Chrome Web Store"}</p>
                                </button>
                            </a> enable when Firefox version released */}
                            <a className="hidden sm:!block" href="https://chrome.google.com/webstore/detail/rovalk-the-roblox-trading/oifhhghkjjhnhonfghmdmkjbomnhblbf" target="_blank" rel="noreferrer">
                                <button type="button" className="text-white bg-theme hover:bg-[#420BA1] transition-colors focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-base px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500">
                                    <img className="object-cover p-1 h-16 w-16 mr-3 text-xl rounded-t-lg md:rounded-none md:rounded-l-lg" src={chromeWebStoreImage} alt="install the extension"></img>
                                    <p className="text-3xl">Install on {(getBrowserName() !== "Firefox" && getBrowserName())  || "the Chrome Web Store"}</p>
                                </button>
                            </a>
                    </div>
                    <div className="text-slate-700 dark:text-slate-300 mb-10">
                        <p>RoValk is a browser extension designed to improve limited item trading on roblox.com.</p>
                        <h2 className="text-3xl mb-3">Features include:</h2>
                        <div className="flex flex-wrap gap-2 justify-center ">
                            <FeatureCard
                                title="Values on Trading Window"
                                link="/features/values-on-trading-window"
                                image={TradeValues1}
                                description="The Values on Trading Window feature adds third party item values sourced from rolimons.com to your trading related pages."
                            />
                            <FeatureCard
                                title="Values on Trade Lists"
                                link="/features/values-on-trade-lists"
                                image={TradeListValues3}
                                description="The Values on Trade Lists feature adds previews of the value totals and color codes wins and losses for each individual trade on the trade list."
                            />
                            <FeatureCard
                                title="Trade Notifications"
                                link="/features/inbound-trade-notifications"
                                image={TradeNotifier1}
                                description="This feature sends you a desktop push notification whenever you receive a trade, or your trades are accepted or declined."
                            />
                        </div>
                        <p className="text-2xl">And many more!</p>
                        <Link to="/features"><p className="text-gray-500 hover:underline">See all features</p></Link>
                    </div>
                    <h2 className="text-6xl mb-2 text-slate-700 dark:text-slate-100">FAQ</h2>
                    <div id="faq" className="max-w-[95%] lg:max-w-[75%] m-auto shadow-lg text-left !mb-5 ">
                        <HomeAccordian />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;