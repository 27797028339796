import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import Home from './Home';
import Installed from './Installed';
import PrivacyPolicy from './PrivacyPolicy';
import Navbar from "./Navbar";
import Footer from "./Footer";
import UpdateLog from './UpdateLog';
import Features from './Features';
import About from "./About";
import Contact from './Contact';
import NotFound from "./NotFound";
import DiscordRedirect from './DiscordRedirect';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Navbar />
        <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/installed" element={<Installed />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/updates" element={<UpdateLog />} />
            <Route path="/features/*" element={<Features />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path='/discordjoin' element={ <DiscordRedirect/> }/>
        </Routes>
        <Footer />
    </BrowserRouter>

);
