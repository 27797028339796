const Breadcrumbs = (props) => {
    return (
        <div className="p-3 bg-slate-100 border-b-[1px] border-slate-200 text-slate-600 dark:text-slate-300 dark:bg-bgDark text-sm">
            <div className="max-w-[1185px] m-auto">
                <span><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 mr-3" href="/">Home</a>
                    {props.pageList.map((pageName, index) => (
                        <span key={index} className="mr-3"><span className="text-slate-300 mr-3">/</span>{pageName}</span>
                    ))}
                </span>
            </div>
        </div>
    );
}

export default Breadcrumbs;