import './styles/output.css';
import DiscordInvite from './DiscordInvite';

function App() {
  return (
    <div className="App">
      <header className="App-header text-center">
        <hr className='border-lightTheme' />
        <div className="bg-theme p-5">
          <h1 className="text-2xl font-medium lg:text-4xl text-white tracking-wide mb-3">RoValk has been installed</h1>
          <p className="text-white text-base tracking-wide">Thank you for installing the browser extension!</p>
        </div>
      </header>
      <div className="mt-10 m-auto p-6 shadow-lg  max-w-[66.6666%] rounded-md text-left mb-12">
        <h3 className="text-2xl text-slate-700 dark:text-slate-300 mb-3">Getting started</h3>
        <ol className="list-decimal m-auto text-slate-600 dark:text-slate-300 ml-4 leading-8 pb-4">
          <li>Check out your upgraded trades page at <a href="https://www.roblox.com/trades" target="_blank" rel="noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" >roblox.com/trades</a></li>
          <li>See all of the features available and how to use them on our <a href="/features" target="_blank" rel="noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" >Features</a> page</li>
          <li>Join our Discord community</li>
        </ol>
        <DiscordInvite/>
      </div>
    </div>
  );
}

export default App;
