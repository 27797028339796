import Breadcrumbs from "./Breadcrumbs";
import { Link } from "react-router-dom";
import FeaturesList from "./FeaturesList";
import options from "./data/options.json";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';

const Features = () => {
    const location = useLocation();
    useEffect(() => {
        setCurrentFeature(getOptionNameByPath()?.name || "Feature Overview");
    }, [location]);

    function getOptionNameByPath() {
        return options.find((option) => window.location.pathname === "/features/" + option?.path);
    }
    const [currentFeature, setCurrentFeature] = useState(getOptionNameByPath()?.name || "Feature Overview");
    const [searchText, setSearchText] = useState("");

    function getFilteredList(searchPattern) {
        if (searchPattern !== "") {
            let optionObjects = [];
            for (let line of options) {
                if (!(typeof line === "string")) {
                    optionObjects.push(line);
                }
            }
            const searchOptions = {
                keys: [
                    "name"
                ]
            };
            const fuse = new Fuse(optionObjects, searchOptions);
            const results = fuse.search(searchPattern);
            let filteredOptionObjects = [];
            for (let result of results){
                filteredOptionObjects.push(result.item);
            }
            return filteredOptionObjects;
        } else {
            return options;
        }
    }
    return (
        <div className="App">
            <Breadcrumbs pageList={["Features", [currentFeature]]} />

            <div className="drawer drawer-mobile h-full">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content">
                    <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden bg-transparent border-0 text-black">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </label>
                    <FeaturesList currentFeature={currentFeature} setCurrentFeature={setCurrentFeature} />
                </div>
                <div className="drawer-side !max-h-[80vh]  text-slate-700 dark:text-slate-300 dark:bg-bgLightDark overflow-x-hidden">
                    <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                    
                    <ul className="menu p-4 overflow-y-auto overflow-x-hidden w-80  text-slate-700 dark:text-slate-300">
                    <input type="text" value={searchText} onInput={e => setSearchText(e.target.value)} placeholder="Search for a feature" className="input input-bordered bg-white dark:!bg-gray-800" />
                        
                        <ul className="menu w-56 p-2 rounded-box mt-5 shadow-sm ">
                            {searchText === "" && (
                                <li className="hover-bordered bg-lightGrey dark:bg-bgDark rounded-md"><Link to="./overview" onClick={() => { setCurrentFeature("Feature Overview") }}><strong>Feature Overview</strong></Link></li>
                            )}

                            {getFilteredList(searchText).map((line, index) => {
                                if (typeof line === "string") {
                                    return (
                                        <li key={index} className="menu-title">
                                            <span className="text-slate-400 dark:!text-slate-300">{line}</span>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={index} className="hover-bordered bg-lightGrey dark:bg-bgDark m-1 rounded-md"><Link to={`./${line.path}`} onClick={() => { setCurrentFeature(line.name) }}>{line.name}</Link></li>
                                    );
                                }
                            })}
                        </ul>
                    </ul>

                </div>
            </div>
        </div>
    );
}

export default Features;