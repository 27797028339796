import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import { Link } from "react-router-dom";
import TradeNotifier1 from "../images/TradeNotifier1.png";
const TradeNotifier = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature sends you a desktop push notification whenever one of your trades has been accepted.</p>

            <p className="mb-3">This feature is exactly the same as <Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`./${"Inbound Trade Notifications".replace(/ /g, '-').toLowerCase()}`} onClick={() => { props.setCurrentFeature("Inbound Trade Notifications") }}>Inbound Trade Notifications</Link>, except that it is for completed trades instead of inbounds. See the documentation for Inbound Trade Notifications for more details about how the RoValk notification system works.</p>

            <ImageCarousel frames={[{ image: TradeNotifier1, wide: true }]} />
        </div>
    );
}

export default TradeNotifier;