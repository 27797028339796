import { useLayoutEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import { Link } from "react-router-dom";
import TradeNotifier1 from "../images/TradeNotifier1.png";
const TradeNotifier = (props) => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="lg:ml-4 px-2 p-1">
            <p className="mb-3">This feature sends you a desktop push notification whenever you receive a new inbound trade.</p>

            <div id="alert-additional-content-5" className="p-4 bg-gray-100 rounded-lg dark:bg-gray-700" role="alert">
                <div className="flex items-center">
                    <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-700 dark:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Enable</span>
                    <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">Enabling</h3>
                </div>
                <div className="mt-2 mb-1 text-sm text-gray-700 dark:text-gray-300">
                    <p>When you first enable this feature from the options menu, you will be prompted to allow the extension permissions to "Display notifications". Click “Allow” to give the extension permission to send you notifications when you receive trades.</p>
                </div>
            </div>

            <div className="mx-2 my-4">
                <h2 className=" text-2xl">Details</h2>
                <ul className=" list-disc">
                    <li className="ml-3"><p>This feature is only for inbound notifications. If you want declined and completed notifications as well, then enable <Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`./${"Declined Trade Notifications".replace(/ /g, '-').toLowerCase()}`} onClick={() => { props.setCurrentFeature("Declined Trade Notifications") }}>Declined Trade Notifications</Link> and <Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`./${"Accepted Trade Notifications".replace(/ /g, '-').toLowerCase()}`} onClick={() => { props.setCurrentFeature("Accepted Trade Notifications") }}>Accepted Trade Notifications</Link>.</p></li>
                    <li className="ml-3"><p>This feature is not available on mobile.</p></li>
                    <li className="ml-3"><p>Notifications may be delayed up to one minute due to restrictions of Google’s Alarms API.</p></li>
                    <li className="ml-3"><p>By default, the Trade Notifier feature is disabled so that the notifications permission is not required to install the extension.</p></li>
                </ul>
            </div>

            <ImageCarousel frames={[{ image: TradeNotifier1, wide: true }]} />
        </div>
    );
}

export default TradeNotifier;